.web-client {
  width: calc(100vw);
  height: calc(100vh - 64px);
  background-color: black;
  position: relative;
  > .canvas-layer {
    width: 100%;
    height: 100%;
  }
}

