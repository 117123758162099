@import "../../../../../mt-canvus/Style/Colors.less";
@import "~antd/lib/style/themes/default.less";

.anchor-list {
  position: absolute;
  background: white;
  width: 330px;
  height: calc(100vh - 64px);
  overflow: hidden auto;
  > .ant-btn {
    float: right;
    margin: 12px 0;
    font-size: 16px;
  }
  > h2 {
    margin: 16px;
    font-size: 20px;
  }
  .buttons {
    display: flex;
    > .play-button {
      margin-left: 16px;
      > .fa-circle-play {
        margin-right: 10px;
      }
    }
    > .label {
      text-align: right;
      flex: 1;
      margin-right: 16px;
    }
    > .switch {
      margin-right: 16px;
    }
  }
  .ant-divider {
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    margin: 16px 16px 0 16px;
  }
  > .tiles {
    padding: 0 8px 16px 8px;
    display: flex;
    flex-direction: column;
    > .anchor-tile {
      border-radius: 2px;
      width: 298px;
      margin: 8px;
      padding: 8px 8px 0 8px;
      > .ant-typography {
        font-size: 16px;
        margin-bottom: 8px;
      }
      > .dotdotdot {
        display: none;
        float: right;
      }
      &:hover {
        background: @item-hover-bg;
      }
    }
    > .selected,
    > .selected:hover {
      background: @primary-color;
      > .ant-typography,
      .dotdotdot {
        color: white;
      }
    }
  }
}

