@import "../../../../../mt-canvus/Style/Colors.less";

.ZoomToolbar {
  position: absolute;
  right: 16px;
}

.Toolbar {
  box-shadow: 0px 0px 8px @drop-shadow-color;
  background-color: @gray-2;
  border-radius: 4px;
  display: inline-flex;
  margin-left: 10px;

  > .ToolbarButton {
    width: 64px;
    height: 55px;
    color: @brand-10;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-left: 1px solid @gray-5;
    &:first-child {
      border-left: none;
    }
    &:disabled {
      opacity: 50%;
    }
  }
}

