@import "../../../../../mt-canvus/Style/Colors.less";

.title-bar {
  position: absolute;
  background: @gray-4;
  border: 1px solid @gray-5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: flex-end;
  transition: margin-top 200ms ease;

  > .icon {
    margin-left: 10px;
    margin-right: 4px;
  }

  > .title {
    margin-left: 6px;
    margin-right: 6px;
    flex-grow: 1;
    white-space: nowrap;
  }

  > .divider {
    margin-left: 6px;
    margin-right: 6px;
    border-right: 1px solid @border-color;
    width: 1px;
    height: calc(100% - 16px);
  }

  > .menu-icon {
    size: 24px 24px;
    margin: 8px 12px 7px 6px;
  }
}

